import React from "react";

function Footer() {

    return (
        
        <div className ="row footer">
            <div className="col py-md-4 py-3 text-center">
                <span>
                    2023 Kicks. All Rights Reserved
                </span>
            </div>
        </div>

    )
}

export default Footer;